import React from "react";
import theme from "theme";
import { Theme, Strong, Em, Span, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Піцерія Bella Gusto
			</title>
			<meta name={"description"} content={"Пориньте в наш світ ароматної піци, випеченої до досконалості, і дозвольте своїм смаковим рецепторам вирушити в кулінарну подорож, яка не має собі рівних за смаком і свіжістю."} />
			<meta property={"og:title"} content={"Піцерія Bella Gusto"} />
			<meta property={"og:description"} content={"Пориньте в наш світ ароматної піци, випеченої до досконалості, і дозвольте своїм смаковим рецепторам вирушити в кулінарну подорож, яка не має собі рівних за смаком і свіжістю."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				<Strong
					font="--headline3"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Bella Gusto Pizzeria
				</Strong>
			</Override>
		</Components.Header>
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z) center/cover repeat fixed padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="rgba(255, 255, 255, 0.9)"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					border-radius="30px"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" text-align="center">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Em>
								<Strong>
									Де традиції зустрічаються зі смаком у кожному шматочку
								</Strong>
							</Em>
						</Span>
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Пориньте в наш світ ароматної піци, випеченої до досконалості, і дозвольте своїм смаковим рецепторам вирушити в кулінарну подорож, яка не має собі рівних за смаком і свіжістю.
					</Text>
					<Button background="--color-darkL1" padding="12px 30px 12px 30px" border-radius="25px">
						Меню
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У піцерії Bella Gusto ми пишаємося тим, що використовуємо лише найкращі інгредієнти з місцевих джерел, щоб підтримати нашу спільноту та забезпечити максимальну свіжість. Наші досвідчені кухарі поєднують перевірені часом рецепти з інноваційними техніками, щоб створити піцу, яка не тільки задовольнить вашу тягу, але й запалить ваш смак. Кожна піца є шедевром, створеним з турботою та пристрастю, що відображає наше прагнення до якості та смаку.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11:53:27.160Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-3.jpg?v=2024-06-10T11%3A53%3A27.160Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 50% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						src="https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11:53:27.132Z"
						position="absolute"
						width="100%"
						bottom="0px"
						right={0}
						min-height="100%"
						object-fit="cover"
						display="block"
						top="auto"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666e55e1d712a002334ca52/images/1-2.jpg?v=2024-06-10T11%3A53%3A27.132Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="58%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Вибір Bella Gusto означає вибір якості, яку ви можете смакувати. Ми прагнемо створювати не просто їжу, а й досвід, який об’єднує людей.{" "}
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="40%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ось чому багато хто обирає нас:{" "}
							<br />
							<br />
						</Strong>
						Гарантована свіжість: Кожен інгредієнт підібрано вручну, щоб отримати найсвіжіший і найсмачніший результат.{"\n"}
						<br />
						Зорієнтованість на громаду: Ми віримо в зміцнення нашої спільноти, підтримку місцевих фермерів і бізнесу.
Інноваційні меню:{" "}
						<br />
						Меню, що постійно вдосконалюються, роблять ваші страви захоплюючими з кожним відвідуванням.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer>
			<Override slot="text1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});